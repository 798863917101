exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agendamento-finalizado-js": () => import("./../../../src/pages/agendamento-finalizado.js" /* webpackChunkName: "component---src-pages-agendamento-finalizado-js" */),
  "component---src-pages-agendamento-js": () => import("./../../../src/pages/agendamento.js" /* webpackChunkName: "component---src-pages-agendamento-js" */),
  "component---src-pages-blog-page-js": () => import("./../../../src/pages/blogPage.js" /* webpackChunkName: "component---src-pages-blog-page-js" */),
  "component---src-pages-consultas-medicas-js": () => import("./../../../src/pages/consultas-medicas.js" /* webpackChunkName: "component---src-pages-consultas-medicas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-renovacao-de-receita-js": () => import("./../../../src/pages/renovacao-de-receita.js" /* webpackChunkName: "component---src-pages-renovacao-de-receita-js" */),
  "component---src-pages-sobre-mim-js": () => import("./../../../src/pages/sobre-mim.js" /* webpackChunkName: "component---src-pages-sobre-mim-js" */),
  "component---src-pages-telemedicina-js": () => import("./../../../src/pages/telemedicina.js" /* webpackChunkName: "component---src-pages-telemedicina-js" */),
  "component---src-pages-termos-de-servico-js": () => import("./../../../src/pages/termos-de-servico.js" /* webpackChunkName: "component---src-pages-termos-de-servico-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

