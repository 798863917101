// src/utils/loadImageDimensions.js

const loadImageDimensions = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };
  
  export default loadImageDimensions; 