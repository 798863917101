import './src/styles/global.css';
import { injectSpeedInsights } from '@vercel/speed-insights';
import React, { useEffect } from 'react';
import loadImageDimensions from './src/utils/loadImageDimensions';

// Inject Speed Insights
export const onClientEntry = () => {
  injectSpeedInsights();
};

// Service Worker update handling
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'Este aplicativo foi atualizado. Recarregar para exibir a versão mais recente?'
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const onServiceWorkerInstalled = ({ serviceWorker }) => {
  if (serviceWorker.waiting) {
    serviceWorker.postMessage({ type: 'SKIP_WAITING' });
  }

  serviceWorker.addEventListener('statechange', (e) => {
    if (e.target.state === 'activated') {
      window.location.reload();
    }
  });
};

// Wrapper component to adjust image dimensions
const Wrapper = ({ element }) => {
  useEffect(() => {
    const adjustImageDimensions = async () => {
      const images = document.querySelectorAll('img');
      for (const img of images) {
        if (!img.hasAttribute('width') || !img.hasAttribute('height')) {
          const dimensions = await loadImageDimensions(img.src);
          img.setAttribute('width', dimensions.width);
          img.setAttribute('height', dimensions.height);
        }
      }
    };

    adjustImageDimensions();
  }, []);

  return <>{element}</>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Wrapper element={element} {...props} />;
};